import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import HomeScreen from './screens/HomeScreen';
import NavTop from './components/NavTop';
import NavBot from './components/NavBot';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <NavTop />
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/" element={<HomeScreen />} />
          </Routes>
        <NavBot />
      </BrowserRouter>
    </>
  );
}

export default App;
