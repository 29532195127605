import React from 'react'
import { useNavigate } from 'react-router-dom'

const NavTop = () => {
    const nav = useNavigate();
  return (
    <>
        <div className='bg-light fixed-top'>
            <div className='container-fluid'>
                <div className='row  justify-content-center'>
                    <div className='col-lg-6'>
                        <nav className="navbar navbar-light">
                            <div className="container ">
                                <span className="navbar-brand " onClick={ () => nav("/") }>
                                </span>
                                <span className="navbar-brand " onClick={ () => nav("/") }>
                                    <small className='rubik-iso'>{process.env.REACT_APP_NAME}</small>
                                </span>
                                <span className="navbar-brand " onClick={ () => nav("/") }>
                                </span>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default NavTop