import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import NavBot from '../components/NavBot';
import NavTop from '../components/NavTop';
import { toast } from 'react-toastify'

const HomeScreen = () => {
    const nav = useNavigate();
    const [imageProfile,setImageProfile] = useState([]);
    const [instagramAccount,setInstagramAccount] = useState([]);
    const [copySuccess, setCopySuccess] = useState('');

    const getProfile = async () => {
        try {
            const res = await axios({
                method: 'post',
                url: "http://172.16.13.122:8001/api/"+"home/getProfile",
                data: {
                    instagramAccount: instagramAccount
                }
            })

            if (res.data.code === '200') {
                setImageProfile(res.data.data.user.profile_pic_url);
                console.log(res.data.data.user.profile_pic_url);
            } else {
                alert("error");
            }
        } catch (error) {
            console.log(error)   
        }
    }


    // your function to copy here

    const copyToClipBoard = async copyMe => {
        try {
        await navigator.clipboard.writeText(copyMe);
            toast.success('Copied!');
        } catch (err) {
            setCopySuccess('Failed to copy!');
        }
    };


  return (
    
    <>
        <div className='py-5 my-5'>
            <div className='container-fluid'>
                <div className='row justify-content-center'>
                    <div className='col-lg-6'>
                        <div className='card-body shadow rounded text-center'>
                            
                        {/* <iframe width="1000" height="1000" src="https://scontent-cgk1-1.cdninstagram.com/v/t51.2885-19/67644845_368869750656377_1456176427979243520_n.jpg?stp=dst-jpg_s150x150&_nc_ht=scontent-cgk1-1.cdninstagram.com&_nc_cat=105&_nc_ohc=v-ixICPrITgAX9Dtcal&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AT-NRmkLrscdAQ71kfqn1ttRpRfRLESiMOVsz8uenLF7rw&oe=62FE0F40&_nc_sid=8fd12b"></iframe> */}


                            <div className='profil-container p-3 pt-5'>
                                <img id="imageProfileIG" class="img-circle" crossOrigin="anonymous" src="https://images.unsplash.com/photo-1594751543129-6701ad444259?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8ZGFyayUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80" alt='profile user' />
                            </div>
                            <div className='message-container p-3 pb-5'>
                                <p className='fs-3'>gabut nih, tanyain gue apapun terserah lo</p>
                            </div>
                            <div className='buttons-container'></div>
                        </div>

                        <div className='card-body bg-light mt-5'>
                            <div className='py-3 text-center'>
                                <p className='fs-3 fw-bold'>1. Copy your link</p>
                            </div>
                            <div className='pb-3 row justify-content-center'>
                                <div className='col-md-5'>
                                <input className=' form-control' defaultValue={"https://aldyweb.com/"} value={instagramAccount} onChange={ e => setInstagramAccount(e.target.value)} />

                                </div>
                            </div>

                            <div className='py-3 text-center'>
                                <button className='btn btn-outline-primary' onClick={() => copyToClipBoard(instagramAccount)}>
                                    Click here to copy
                                </button>
                            </div>
                        </div>

                        <div className='card-body bg-light mt-5'>
                            <div className='py-3 text-center'>
                                <p className='fs-3 fw-bold'>2. Share link on your social media story</p>
                            </div>
                            <div className='pb-3 row justify-content-center'>
                                <div className='col-md-5'>
                                <input className=' form-control' defaultValue={"https://aldyweb.com/"} value={instagramAccount} onChange={ e => setInstagramAccount(e.target.value)} />

                                </div>
                            </div>

                            <div className='py-3 text-center'>
                                <button className='btn btn-outline-primary' data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    Share your link!
                                </button>

                                <div>
                                    <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <div className="modal-body">
                                                    <div className='container-img py-3'>
                                                        <img width={'cover'} height={250} src='https://i.ytimg.com/vi/K7blnZn21dk/maxresdefault.jpg' alt="link" />
                                                    </div>
                                                    <div className='container-btn py-3'>
                                                        <div className='row justify-content-center'>
                                                            <div className='col-5'>
                                                                <button className='btn btn-outline-secondary  d-block d-flex align-items-center'>
                                                                    <span className='fs-5'>NEXT STEP</span> 
                                                                    <span class=" material-symbols-outlined">
                                                                    arrow_forward
                                                                    </span>
                                                                </button>
                                                            </div>

                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default HomeScreen