import React from 'react'
import { useNavigate } from 'react-router-dom'

const NavBot = () => {
    const nav = useNavigate();
    
  return (
    
    <>
        <div className='bg-light fixed-bottom'>
            <div className='container-fluid'>
                <div className='row  justify-content-center'>
                    <div className='col-lg-6'>
                        <nav className="navbar navbar-light">
                            <div className="container text-center">
                                <span className={"navbar-brand btn"+(window.location.pathname.split("/").pop()==="" || window.location.pathname.split("/").pop()==="home"?" text-primary":"")} onClick={ () => nav("/") }>
                                    <span class="material-symbols-outlined fs-3">
                                        home
                                    </span> 
                                    <br></br>
                                    <small className='fs-6'>Home</small>
                                </span>
                                <span className={"navbar-brand btn"+(window.location.pathname.split("/").pop()==="messages"?" text-primary":"")} onClick={ () => nav("/messages") }>
                                    <span class="material-symbols-outlined fs-3">
                                    forum
                                    </span>
                                    <br></br>
                                    <small className='fs-6'>Message</small>
                                </span>

                                <span className={"navbar-brand btn"+(window.location.pathname.split("/").pop()==="account"?" text-primary":"")} onClick={ () => nav("/account") }>
                                    <span class="material-symbols-outlined fs-3">
                                    person
                                    </span>
                                    <br></br>
                                    <small className='fs-6'>Account</small>
                                </span>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default NavBot